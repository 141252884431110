












import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {State} from 'vuex-class'
import Utils from '@/utils'
import DateHelper from '@/lib/DateHelper'
import moment from 'moment/moment'
import {ReservationResponse, ReservationStatus} from '@/lib/kepler/interfaces'

interface Highlight {
  title: string
  content: string
  subcontent?: string
}

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Divider: Utils.loadComponent('proxy/Divider'),
  },
  name: 'ReservationHighlights',
})
export default class ReservationHighlights extends Vue {
  @State((state) => state.profile.driver.id) public driverId!: string
  @Prop({type: Object, required: true}) public reservation!: ReservationResponse

  public highlights: Highlight[] = []
  public updateInterval: NodeJS.Timeout | null = null

  /* tslint:disable: cyclomatic-complexity */ // Huge computed be huge
  public getHighlights() {
    const r = this.reservation
    const h: Highlight[] = []

    if (r) {
      const charged = typeof r.cost === 'number' && r.status === ReservationStatus.CHARGED
      const cost = charged ? `${this.$currency(r.cost as number)}` : null
      const roundTrip = r.type === 'RT' || r.type === 'CRT' || r.type === 'STR'
      const freeFloating = r.type === 'FF'

      if (roundTrip) {
        if (r.start) {
          h.push({
            title: this.$t('booking.check_in'),
            content: DateHelper.formatDate(r.start, this.$dateformat()),
            subcontent: DateHelper.formatDate(r.start, this.$timeformat()),
          })
        }
        if (r.end) {
          h.push({
            title: this.$t('booking.check_out'),
            content: DateHelper.formatDate(r.end, this.$dateformat()),
            subcontent: DateHelper.formatDate(r.end, this.$timeformat()),
          })
        }
        h.push({
          title: r.status !== ReservationStatus.STARTED ? this.$t('booking.duration') : this.$t('booking.elapsed'),
          content: DateHelper.getTimeOffset(r.start_timestamp, r.end_timestamp || new Date()).formatTimeOffset('dhm'),
          subcontent: '',
        })
      } else {
        h.push({
          title: `${this.$t('booking.start_time')}`,
          content: this.startTime[0],
          subcontent: this.startTime[1],
        })
        if (freeFloating && r.distance !== null) {
          h.push({
            title: this.$t('booking.trips_distance'),
            content: this.$distance(r.distance),
            subcontent: '',
          })
        }
        h.push({
          title: this.$t('booking.elapsed'),
          content: DateHelper.getTimeOffset(r.start_timestamp, r.end_timestamp || new Date()).formatTimeOffset('dhm'),
          subcontent: '',
        })
      }

      if (cost) {
        h.push({
            title: this.$t('booking.total_cost'),
            content: `${cost}`,
            subcontent: '',
          },
        )
      }

      if (this.driverId !== this.reservation.driver.id) {
        h.push({
          title: this.$t('common.driver'),
          content: this.reservation.driver.name,
          subcontent: this.reservation.driver.surname,
        })
      }
    }
    if (!this.updateInterval) {
      this.updateInterval = setInterval(this.onReservationChange, 60000)
    }

    return h
  }

  public get startTime() {
    const r = this.reservation
    if (r.start) {
      if (moment(r.start).isSame(moment(), 'day')) {
        return [DateHelper.formatDate(r.start, this.$timeformat()), '']
      } else {
        return [
          DateHelper.formatDate(r.start, this.$dateformat()),
          DateHelper.formatDate(r.start, this.$timeformat()),
        ]
      }
    }
    return ['', '']
  }

  @Watch('reservation', {immediate: true, deep: true})
  public onReservationChange() {
    this.highlights = this.getHighlights()
  }

  public beforeDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }
  }
}
